/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" d="M8.525 3.838L3.33.867a1.87 1.87 0 00-2.535.671c-.163.28-.248.597-.248.92V8.4c-.002.323.083.64.246.921.163.28.397.513.68.674a1.865 1.865 0 001.857-.004l5.196-2.972c.283-.16.518-.393.68-.672a1.822 1.822 0 00-.68-2.51v.001zm-.835 1.75L2.494 8.56a.184.184 0 01-.186 0 .176.176 0 01-.092-.158V2.457a.174.174 0 01.092-.158.178.178 0 01.186 0L7.69 5.27a.184.184 0 01.092.159.182.182 0 01-.092.158v.002z" _fill="#fff"/>'
  }
})
